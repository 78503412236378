import React from 'react';
import './App.css';
import { CssBaseline } from '@material-ui/core';
import { Main } from './main/Main';

function App() {
  return (
    <div className="App">
      <CssBaseline />
      <div style={{backgroundColor: '#9082DB', height: '100vh'}}>
        <Main />
      </div>
    </div>
  );
}

export default App;
