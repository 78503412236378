import React, { ReactNode } from 'react';
import {
  createMuiTheme, createStyles, makeStyles, ThemeProvider,
} from '@material-ui/core/styles';
import {
  AppBar, Avatar, CssBaseline, Theme, Toolbar,
} from '@material-ui/core';
import CookieConsent from 'react-cookie-consent';
import { useIsSmallerThen } from '../hooks/utls';

const htmlString = `

   <div id="mlb2-10650167" class="ml-form-embedContainer ml-subscribe-form ml-subscribe-form-10650167">
      <div class="ml-form-align-center ">
        <div class="ml-form-embedWrapper embedForm">


          <div class="ml-form-embedHeader">

            <img src="https://storage.mlcdn.com/account_image/730670/YxK7KDUI3gpJHry3mLcnGVgm0Q8SV58Lx8tyKFcR.png" border="0" style="display: block;">

            <style>
              @media only screen and (max-width: 400px){
                .ml-form-embedHeader { display: none !important; }
              }
            </style>


          </div>

          <div class="ml-form-embedBody ml-form-embedBodyDefault row-form">

            <div class="ml-form-embedContent" style=" ">

              <h4>Newsletter</h4>
              <p>Zapisz się do newslettera i otrzymaj <strong>3 darmowe </strong>plany treningowe z linkami do pełnych treningów!</p>
              <ol><li>Plan <strong>Redukcja Wagi</strong></li><li>Plan <strong>Brzuch i Pośladki</strong></li><li>Plan <strong>Zdrowy Kręgosłup</strong></li></ol>
              <p><span style="font-size: 10px;">Jeśli chcesz otrzymać plany w inny sposób, skontaktuj się ze mną.</span></p>

            </div>

            <form class="ml-block-form" action="https://assets.mailerlite.com/jsonp/730670/forms/109443894801860105/subscribe" data-code="" method="post" target="_blank">
              <div class="ml-form-formContent">



                <div class="ml-form-fieldRow ">
                  <div class="ml-field-group ml-field-name">




                    <!-- input -->
                    <input aria-label="name" type="text" class="form-control" data-inputmask="" name="fields[name]" placeholder="Imię" autocomplete="given-name">
                    <!-- /input -->

                    <!-- textarea -->

                    <!-- /textarea -->

                    <!-- select -->

                    <!-- /select -->

                    <!-- checkboxes -->

                    <!-- /checkboxes -->

                    <!-- radio -->

                    <!-- /radio -->

                    <!-- countries -->

                    <!-- /countries -->





                  </div>
                </div><div class="ml-form-fieldRow ">
                <div class="ml-field-group ml-field-email ml-validate-email ml-validate-required">




                  <!-- input -->
                  <input aria-label="email" aria-required="true" type="email" class="form-control" data-inputmask="" name="fields[email]" placeholder="Email" autocomplete="email">
                  <!-- /input -->

                  <!-- textarea -->

                  <!-- /textarea -->

                  <!-- select -->

                  <!-- /select -->

                  <!-- checkboxes -->

                  <!-- /checkboxes -->

                  <!-- radio -->

                  <!-- /radio -->

                  <!-- countries -->

                  <!-- /countries -->





                </div>
              </div><div class="ml-form-fieldRow ml-last-item">
                <div class="ml-field-group ml-field-last_name ml-validate-required">




                  <!-- input -->

                  <!-- /input -->

                  <!-- textarea -->

                  <!-- /textarea -->

                  <!-- select -->

                  <!-- /select -->

                  <!-- checkboxes -->
                  <div class="custom-checkbox-radio-list">
                    <!-- Visible if current or any next has value -->
                    <div class="custom-control custom-checkbox">
                      <input name="fields[last_name][]" aria-label="last_name" aria-required="true" class="custom-control-input" type="checkbox" value="Wyrażam zgodę na przetwarzanie podanych danych osobowych, w celu przesłania planów treningowych. Jednocześnie wyrażam zgodę na otrzymywanie newslettera." id="checkbox-10650167-23-0">
                      <label class="custom-control-label" for="checkbox-10650167-23-0">
                        Wyrażam zgodę na przetwarzanie podanych danych osobowych, w celu przesłania planów treningowych. Jednocześnie wyrażam zgodę na otrzymywanie newslettera.
                      </label>
                    </div>


















                  </div>
                  <!-- /checkboxes -->

                  <!-- radio -->

                  <!-- /radio -->

                  <!-- countries -->

                  <!-- /countries -->





                </div>
              </div>

              </div>



              <!-- Privacy policy -->

              <!-- /Privacy policy -->













              <input type="hidden" name="ml-submit" value="1">

              <div class="ml-form-embedSubmit">

                <button type="submit" class="primary">Chcę odebrać treningi!</button>

                <button disabled="disabled" style="display: none;" type="button" class="loading">
                  <div class="ml-form-embedSubmitLoad"></div>
                  <span class="sr-only">Loading...</span>
                </button>
              </div>


              <input type="hidden" name="anticsrf" value="true">
            </form>
          </div>

          <div class="ml-form-successBody row-success" style="display: none">

            <div class="ml-form-successContent">

              <h4>Zapisałaś się!</h4>
              <p></p>
              <p>Od otrzymania <strong>planów treningowych</strong> dzieli Cię jeden krok!</p>
              <p><strong>Wejdź na swoją skrzynkę e-mail i potwierdź zapis do newslettera! </strong><br></p>
              <p><em>Jeśli nie widzisz maila ode mnie,&nbsp;przeszukaj folder OFERTY oraz SPAM.</em></p>
              <p></p>

            </div>

          </div>
        </div>
      </div>
    </div>
  `;


const theme = createMuiTheme({
  typography: {
    fontFamily: 'Rogan',
  },
  palette: {
    primary: {
      main: '#9164ab',
    },
    secondary: {
      main: '#6667ab',
    },
  },
});

const useStyles = makeStyles((th: Theme) => createStyles({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: th.spacing(2),
  },
  title: {
    flexGrow: 1,
    marginRight: th.spacing(2),
    textAlign: 'start',
    [th.breakpoints.down('sm')]: {
      marginRight: 0,
    },
  },
  large: {
    width: th.spacing(10),
    height: th.spacing(7),
    borderRadius: '0%',
  },
  sectionDesktop: {
    [th.breakpoints.up('sm')]: {
      display: 'flex',
    },
    [th.breakpoints.down('sm')]: {
      padding: 1,
      marginRight: theme.spacing(1),
    },
  },
  sectionMobile: {
    display: 'flex',
    [th.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));

const handleGoToClick = (id: string, position: 'center' | 'start' = 'center') => (event: React.MouseEvent<HTMLElement>) => {
  const anchor = ((event.target as HTMLDivElement).ownerDocument || document).querySelector(
    `#${id}`,
  );

  if (anchor) {
    anchor.scrollIntoView({ behavior: 'smooth', block: position });
  }
};

export function Layout({ children }: { children: ReactNode }) {
  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div style={{paddingTop: 10}}>
          <div dangerouslySetInnerHTML={{__html: htmlString}}/>
          {children}
        </div>
      </ThemeProvider>
      <CookieConsent buttonText="Zgoda">
        Ta strona wykorzystuje pliki cookie aby dostarczyć tobie jak najlepsze
        doświadczenia. Jeśli chcesz z nich zrezygnować dostosuj ustawienia swojej przeglądarki.
      </CookieConsent>
    </>
  );
}
