import React from 'react';
import { Layout } from '../layout/Layout';
import { Contact } from '../contact/Contact';
import { useIsSmallerThen } from '../hooks/utls';
import { Copyright } from '../copyright/Copyright';
import { Reviews } from '../reviews/Reviews';

export function Main() {
  const isSmallView = useIsSmallerThen('md');
  return (
    <Layout>
        <></>
    </Layout>
  );
}
